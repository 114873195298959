import React from "react";
import AdminUsersList from "../list/AdminUsersList";

const Users = () => {
  return (
    <>
      <AdminUsersList />
    </>
  );
};

export default Users;
